.account-container {
    display: flex;
    height: 850px;
    justify-content: center;
    align-items: center;   
}

.profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile-container img {
    margin-bottom: var(--default-spacing)
}

.profile-container .user-details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(255,255,255,0.4);
}

.profile-container .user-stats {
    margin-top: var(--default-spacing);
}