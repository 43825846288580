.plugins-container {
    display: flex;    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: var(--default-spacing);
    padding-left: var(--default-spacing);
    padding-right: var(--default-spacing);
}

.plugins-container .box:last-child {    
    display: flex;
}


/* .plugins-container .box:first-child {
    flex-grow: 1;
    margin-left: 50px;
    margin-right: 50px;
}

.plugins-container .box:last-child {    
    flex-grow: 1;
    display: flex;
}

.plugins-container .box.plugins-inner-container {
    display: flex;    
} */

.plugins-button {
    cursor: pointer;
    display: flex;

    align-items: center;
    width: 305px;
    height: 125px;
    border-radius: 30px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), #A6A5B0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 2px 2px rgba(255, 255, 255, 0.25);
    margin: 32px;

    text-decoration: none;

    color: gray
}

a {
    text-decoration: none;
}

.plugins-button:hover {
    position: relative;
    top: 2px;
    left: 2px;
}

.plugins-button img {
    height: 50px;
    width: auto;
}

.plugins-button div:first-child {
    flex-grow: 1;
    margin-left: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.plugins-button div:last-child {
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 80px;
    border-left: 2px solid #8E8EA4;    
}